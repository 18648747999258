export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },



  {
    title: "My Order",
    path: "/order",
  },
  {
    title: "My Wallet",
    path: "/wallet",
  },
  {
    title: "Refer & Earn",
    path: "/refer",
  },
  {
    title: "Wishlist",
    path: "/wishlist",
  },
  {
    title: "Contact Us",
    path: "/contact",
  },

];
